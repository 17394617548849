import * as THREE from 'three';
import { OrbitControls } from 'three/addons/controls/OrbitControls.js';
import GUI from 'lil-gui';
import { FontLoader } from 'three/examples/jsm/loaders/FontLoader.js';
import { TextGeometry } from 'three/addons/geometries/TextGeometry.js';

/**
 * SetUp
 */
const canvas = document.querySelector('canvas.webgl');

const sizes = {
    width: window.innerWidth,
    height: window.innerHeight
}

function getAspectRatio() {
    return sizes.width / sizes.height
} 

const scene = new THREE.Scene();

const renderer = new THREE.WebGLRenderer({canvas: canvas});
renderer.setSize(sizes.width, sizes.height); // need to set this before the resize
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));

/**
 * Camera
 */
const camera = new THREE.OrthographicCamera(-5 * getAspectRatio(), 5 * getAspectRatio(), 5, -5)
// const camera = new THREE.PerspectiveCamera(75, getAspectRatio(), 1, 1000)
const controls = new OrbitControls(camera, canvas)
camera.position.set(0, 0, 20);
controls.update();
scene.add(camera); // don't forget to add the camera to the scene haha

/**
 * Objects
 */
const materialSolid = new THREE.MeshBasicMaterial()
const materialWireframe = new THREE.MeshBasicMaterial({ wireframe: true })

const fontLoader = new FontLoader()

fontLoader.load(
    'fonts/helvetiker_regular.typeface.json',
    (font) => 
    {
        const textGeometry = new TextGeometry(
            'Coming Soon', {
                font: font,
                size: 0.5,
                height: 0.2,
                curveSegments: 12,
                bevelEnabled: true,
                bevelThickness: 0.03,
                bevelSize: 0.02,
                bevelOffset: 0,
                bevelSegments: 5
            }
        );

        console.log(textGeometry)

        textGeometry.center();
        const text = new THREE.Mesh(textGeometry, materialSolid);

        scene.add(text);
    }
)

const maxMesh = 40;
const cubeGeometry = new THREE.BoxGeometry();
const displacement = 30;
for (let mesh = 0; mesh < maxMesh; mesh++) {
    const cube = new THREE.Mesh(cubeGeometry, materialWireframe);

    cube.position.set(
        (Math.random() - 0.5) * displacement, 
        (Math.random() - 0.5) * displacement, 
        (Math.random() - 0.5) * displacement
    )

    cube.rotation.set(
        Math.random() * Math.PI,
        Math.random() * Math.PI,
        Math.random() * Math.PI
    )

    scene.add(cube);
}

/**
 * Resizing
 */
window.addEventListener('resize', () => {
    // update the window sizes to match the one in the browser
    sizes.width = window.innerWidth;
    sizes.height = window.innerHeight;

    // update the aspect ratio
    camera.left = -5 * getAspectRatio();
    camera.right = 5 * getAspectRatio();
    camera.top = 5;
    camera.bottom = -5;
    camera.updateProjectionMatrix();

    renderer.setSize(sizes.width, sizes.height);
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
})

/**
 * Main
 */
const clock = new THREE.Clock();

function main() {
    const elapsedTime = clock.getElapsedTime();

    controls.update();

    renderer.render(scene, camera);

    window.requestAnimationFrame(main);
}

main();